var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Doelstellingen")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            disabled: _vm.isLoading,
                            href: "#" + organization.id
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleTabChange(organization.id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _vm.activeOrganization === organization.id
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "justify-start": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                "justify-end": "",
                                                "align-baseline": ""
                                              }
                                            },
                                            [
                                              !_vm.$store.state
                                                .isServiceOrganization
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { shrink: "" } },
                                                    [
                                                      _c(
                                                        "ReportDepartmentFilter",
                                                        {
                                                          attrs: {
                                                            cssClass:
                                                              "with-border",
                                                            multiple: false
                                                          },
                                                          on: {
                                                            handleChange: function(
                                                              $event
                                                            ) {
                                                              return _vm.getTargets()
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedDepartment,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedDepartment = $$v
                                                            },
                                                            expression:
                                                              "selectedDepartment"
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.years,
                                                      "item-value": "key",
                                                      "item-text": "name",
                                                      label: "Jaren"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.getTargets()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.selectedYear,
                                                      callback: function($$v) {
                                                        _vm.selectedYear = $$v
                                                      },
                                                      expression: "selectedYear"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.isLoading
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("LoaderCard", {
                                                    attrs: {
                                                      flat: "",
                                                      type: "spinner--center"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isValid
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  !_vm.isLoading
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c("h2", [
                                                                _vm._v(
                                                                  "Productie doelstelling per maand"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label tw-text-gray-600",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Maand"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Opgegeven beschikbare afspraken (4u. = 1 afspraak)"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# Ingeplande afspraken"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Standaard (30+)"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Herzien Rapporten"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Specials"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Bezwaar"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "WoCo v1"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "WoCo v2"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "VES nulmeting"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.months,
                                                              function(
                                                                month,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "v-layout",
                                                                  {
                                                                    key:
                                                                      index +
                                                                      "-months",
                                                                    attrs: {
                                                                      row: "",
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              month.name
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs2:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.availability
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "availability",
                                                                                  month.availability
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.availability,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "availability",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.availability"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs2:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.planned
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "planned",
                                                                                  month.planned
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.planned,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "planned",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.planned"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production",
                                                                                  month.production
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_zienswijze
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_zienswijze",
                                                                                  month.production_zienswijze
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_zienswijze,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_zienswijze",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_zienswijze"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_specials
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_specials",
                                                                                  month.production_specials
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_specials,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_specials",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_specials"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_objection
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_objection",
                                                                                  month.production_objection
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_objection,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_objection",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_objection"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_woco_v1
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_woco_v1",
                                                                                  month.production_woco_v1
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_woco_v1,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_woco_v1",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_woco_v1"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_woco_v2
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_woco_v2",
                                                                                  month.production_woco_v2
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_woco_v2,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_woco_v2",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_woco_v2"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                month.production_ves
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  month.id,
                                                                                  "production_ves",
                                                                                  month.production_ves
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                month.production_ves,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  month,
                                                                                  "production_ves",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "month.production_ves"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isLoading
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c("h2", [
                                                                _vm._v(
                                                                  "Productie doelstelling per week"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label tw-text-gray-600",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Maand"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Opgegeven beschikbare afspraken (4u. = 1 afspraak)"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# Ingeplande afspraken"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Standaard (30+)"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Herzien Rapporten"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Specials"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Bezwaar"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "WoCo v1"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "WoCo v2"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: "",
                                                                        "text-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "VES nulmeting"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.weeks,
                                                              function(
                                                                week,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "v-layout",
                                                                  {
                                                                    key:
                                                                      index +
                                                                      "-weeks",
                                                                    attrs: {
                                                                      row: "",
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              week.name
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs2:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.availability
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "availability",
                                                                                  week.availability
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.availability,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "availability",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.availability"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs2:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.planned
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "planned",
                                                                                  week.planned
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.planned,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "planned",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.planned"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production",
                                                                                  week.production
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_zienswijze
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_zienswijze",
                                                                                  week.production_zienswijze
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_zienswijze,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_zienswijze",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_zienswijze"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_specials
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_specials",
                                                                                  week.production_specials
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_specials,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_specials",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_specials"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_objection
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_objection",
                                                                                  week.production_objection
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_objection,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_objection",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_objection"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_woco_v1
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_woco_v1",
                                                                                  week.production_woco_v1
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_woco_v1,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_woco_v1",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_woco_v1"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_woco_v2
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_woco_v2",
                                                                                  week.production_woco_v2
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_woco_v2,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_woco_v2",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_woco_v2"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs1:
                                                                            "",
                                                                          "text-xs-right":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 ma-0 input--text-right",
                                                                            attrs: {
                                                                              placeholder:
                                                                                "0",
                                                                              "hide-details":
                                                                                "",
                                                                              value:
                                                                                week.production_ves
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveTarget(
                                                                                  week.id,
                                                                                  "production_ves",
                                                                                  week.production_ves
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                week.production_ves,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  week,
                                                                                  "production_ves",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "week.production_ves"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  !_vm.isLoading
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Selecteer eerst een afdeling"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }