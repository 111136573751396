import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { Target } from '@/models/Target';
import { Organization } from '@/models/Organization';
import { cloneDeep } from 'lodash';
import { YearFilters } from '@/support/years';

@Component<Objectives>({})
export default class Objectives extends Vue {
  public $pageTitle = 'Doelstellingen';

  protected isLoading = false;

  protected months: TargetRow[] = [];

  protected weeks: TargetRow[] = [];

  protected activeOrganization = '';

  protected organizations: Organization[] | null = null;

  protected years: string[] = YearFilters;

  protected selectedYear = '2021';

  protected selectedDepartment = '';

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    if (this.$store.state.isServiceOrganization) {
      await this.getOrganizations();
      await this.getTargets();
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
    }

    this.isLoading = false;
  }

  /**
   *
   * Fetches the Orginazations from the API, filter only the Orgainzation of type expert
   * set the activeOrginazation to the first
   *
   * @protected
   * @memberof AvailabilityPlanner
   */
  protected async getOrganizations() {
    const organizations = await new Organization()
      .getAllExperts()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (organizations && organizations.length && organizations[0].name) {
      this.activeOrganization = organizations[0].id as string;
      this.organizations = organizations;
    }
  }

  protected async getTargets() {
    this.isLoading = true;
    const body: {[key: string]: string} = {
      year: this.selectedYear,
    };

    if (! this.$store.state.isServiceOrganization) {
      body.department = this.selectedDepartment;
    }

    const payload = {
      signature: 'targets:get',
      body,
    };

    await new Rpc()
      .dmz(this.activeOrganization)
      .rpcPost(payload)
      .then((response: any) => {
        this.months = response.months;
        this.weeks = response.weeks;
      });

    this.isLoading = false;
  }

  protected async handleTabChange(id: string) {
    this.isLoading = true;
    this.activeOrganization = id;
    await this.getTargets();
    this.isLoading = false;
  }

  protected saveTarget(targetId: string, key: string, value: string) {
    const payload: {[key: string]: any} = {
      year: this.selectedYear,
    };
    payload[`${key}`] = value;

    if (! this.$store.state.isServiceOrganization) {
      payload.department = this.selectedDepartment;
    }

    new Target({ id: targetId })
      .dmz(this.activeOrganization)
      .update(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Doelstellingen' },
        ],
      });
  }

  protected get isValid() {
    if (this.$store.state.isServiceOrganization) {
      return true;
    }

    return !! this.selectedDepartment && !! this.selectedYear;
  }
}

interface TargetRow {
  name: string;
  starts_at: string;
  ends_at: string;
  production: number;
  availability: number;
  planned: number;
}
